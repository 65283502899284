<template>
  <div
    :class="[
      'marquee',
      {
        'marquee--narrow': props.isNarrow,
        'marquee--desktop-only': props.isDesktopOnly,
      },
    ]"
    :style="marqueeStyle"
  >
    <div class="marquee__wrapper" :style="marqueeWrapperStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  slidesCount?: number
  animationTime?: string
  slideWidth?: string
  marginRight?: string
  isNarrow?: boolean
  gradientColor?: string
  isDesktopOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  slidesCount: 8,
  animationTime: '80',
  slideWidth: '270',
  marginRight: '24',
  isNarrow: false,
  gradientColor: '#f3f4f6',
  isDesktopOnly: false,
})

const marqueeStyle = computed(() => ({
  '--no-of-slides': props.slidesCount,
  '--slide-width': `${props.slideWidth}px`,
  '--max-width': `${props.maxWidth}px`,
  '--gradient-color': `${props.gradientColor}`,
}))

const marqueeWrapperStyle = computed(() => ({
  '--iteration-time': `${props.animationTime}s`,
  '--margin-right': `${props.marginRight}px`,
}))
</script>

<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1));
  }
}
.marquee {
  --gradient-color: #f3f4f6;
  --no-of-slides: 6;
  --slide-width: 270px;
  --margin-right: 24px;

  $gradient-color: var(--gradient-color);

  position: relative;
  overflow: hidden;
  width: calc(2 * var(--no-of-slides));
  margin-left: -20px;
  margin-right: -120px;

  &--narrow {
    width: 1190px;
    margin-left: -70px !important;
  }

  &--desktop-only {
    display: none;
    @media (min-width: $breakpoint-lg) {
      display: block;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin-left: -128px;
    margin-right: -128px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-left: -256px;
    margin-right: -256px;

    &::before,
    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      width: 130px;
      height: 100%;
    }

    &::before {
      left: 0;
      background: linear-gradient(
        270deg,
        rgb(255 255 255 / 0%) 0%,
        $gradient-color 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        90deg,
        rgb(255 255 255 / 0%) 0%,
        $gradient-color 100%
      );
    }
  }
  &__wrapper {
    --iteration-time: 80s;

    display: flex;
    align-items: center;
    width: auto;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: var(--space40);
  }
}

:deep(.marquee__wrapper > *) {
  animation: scroll var(--iteration-time) linear infinite;
  display: flex;
  margin-right: var(--margin-right);
  align-self: stretch;
  flex: 0 0 auto;
  width: var(--slide-width);
}
:deep(.marquee__wrapper:hover > *) {
  animation-play-state: paused;
}
</style>
